import {Component, OnDestroy} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {takeWhile} from 'rxjs/operators';
import {Router} from '@angular/router';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnDestroy {
  // Modal data
  title = '';
  text = '';
  visible = false;
  btnName = 'Close';
  url = null;
  isHtmlTxt = false;
  // Subscription flag
  isSubscribed = true;

  constructor(private modalService: ModalService,
              private router: Router) {
    this.modalService.modalData.pipe(takeWhile(() => this.isSubscribed))
      .subscribe((data: any) => {
        if (data) {
          this.title = data.title || this.title;
          this.text = data.text || this.text;
          this.visible = data.visible;
          this.btnName = data.btnName || this.btnName;
          this.url = data.url || this.url;
          this.isHtmlTxt = data.isHtmlTxt;
        }
      });
  }

  ngOnDestroy(): void {
    this.isSubscribed = false;
  }

  close() {
    this.visible = false;
    // Redirect to url given by argument
    if (this.url) {
      this.router.navigate([this.url]);
      this.url = null;
    }
  }
}
