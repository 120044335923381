import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user';

@Injectable()
export class UserService {
  BASE_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.envName;
  }

  getUsersPagedByClient(clientId, resultsPerPage?, nextPage?, orderBy?, status?, search?) {
    let endpoint = 'usersByClientId';
    endpoint += clientId ? '/' + clientId : '';
    endpoint += resultsPerPage ? '/' + resultsPerPage : '';
    endpoint += nextPage ? '/' + nextPage : '';
    endpoint += orderBy ? '/' + orderBy : '/null';
    endpoint += status ? '/' + status : '/null';
    endpoint += search ? '/' + encodeURIComponent(search) : '/null';
    return this.http.get(this.BASE_URL + endpoint);
  }

  getUserDetails(clientEmail) {
    const endpoint = 'users/' + clientEmail;
    return this.http.get(this.BASE_URL + endpoint);
  }

  createUser(data: User) {
    const endpoint = 'users';
    return this.http.post(this.BASE_URL + endpoint, data);
  }

  editUser(data: User) {
    const endpoint = 'users/' + data.email;
    return this.http.patch(this.BASE_URL + endpoint, data);
  }

  deleteUser(userEmail) {
    const endpoint = 'users/' + userEmail;
    return this.http.delete(this.BASE_URL + endpoint);
  }

  sendActivateEmail(data) {
    const endpoint = 'users/activateEmail/send';
    return this.http.post(this.BASE_URL + endpoint, data);
  }
}
