import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class CalendarService {
  BASE_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.envName;
  }

  listCalendar() {
    const endpoint = 'calendars';
    return this.http.get(this.BASE_URL + endpoint);
  }

  createCalendar(id, data) {
    const endpoint = 'calendarsEvent/' + id;
    return this.http.post(this.BASE_URL + endpoint, data);
  }

  editCalendar(id, data) {
    const endpoint = 'calendars/' + id;
    return this.http.patch(this.BASE_URL + endpoint, data);
  }

  deleteCalendar(id) {
    const endpoint = 'calendars/' + id;
    return this.http.delete(this.BASE_URL + endpoint);
  }

  detailCalendar(id) {
    const endpoint = 'calendars/' + id;
    return this.http.get(this.BASE_URL + endpoint);
  }

  detailCalendarEvent(id, data) {
    const endpoint = 'calendarsEvent/' + id + '/' + data;
    return this.http.get(this.BASE_URL + endpoint);
  }

  editCalendarEvent(id, data) {
    console.log(data);
    const endpoint = 'calendarsEvent/' + id;
    return this.http.patch(this.BASE_URL + endpoint, data);
  }

  deleteCalendarEvent(id, data) {
    const endpoint = 'calendarsEvent/' + id + '/' + data;
    console.log(endpoint);
    return this.http.delete(this.BASE_URL + endpoint);
  }

  createCalendarEvent(id, data) {
    const endpoint = 'calendarsEvent/' + id;
    console.log(endpoint);
    return this.http.post(this.BASE_URL + endpoint, data);
  }

  createCalendarEvents(id, data) {
    const endpoint = 'calendarsEvents/' + id;
    console.log(endpoint);
    return this.http.post(this.BASE_URL + endpoint, data);
  }
}
