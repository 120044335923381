import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ClientService {
  BASE_URL: string;
  clientId = -1; // Shared variable between pages
  clientSub = new BehaviorSubject(null);
  userLoggedClientList = [];

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.envName;
    // Get the client from localstorage in case that the object were previously created
    this.clientId = this.getClientSelected();
  }

  getAllClients() {
    const endpoint = 'clients';
    const dataStored = JSON.parse(localStorage.getItem('clients'));
    if (!dataStored) {
      return this.http.get(this.BASE_URL + endpoint).pipe(map((res: any) => {
        // Save response in local storage
        localStorage.setItem('clients', JSON.stringify(res.clients));
        return res.clients;
      })).toPromise();
    } else {
      // return Promise.resolve(dataStored);
      return Promise.resolve(dataStored);
    }
  }

  async getUserLoggedClients() {
    const sharedClientId = JSON.parse(localStorage.getItem('globalClientIdSelected'));
    // Do this only if list is empty or there is no client selected
    if (this.userLoggedClientList.length <= 0 || !sharedClientId) {
      this.userLoggedClientList = [];
      const profile = JSON.parse(localStorage.getItem('profile'));
      const userClientIds = profile && profile.client_id ? profile.client_id : [];
      const response = await this.getAllClients();
      response.forEach((client) => {
        if (userClientIds.some((id) => client.id === id)) {
          this.userLoggedClientList.push(client);
        }
      });
      // Communicate to the other subscribers
      const clientId = sharedClientId ? sharedClientId : this.userLoggedClientList[0].id;
      const data = {id: clientId, notifySubscribers: false, lastClientId: null, lastProductId: null};
      this.setClientIdSelected(data);
    }
    return Promise.resolve(this.userLoggedClientList);
  }

  setClientIdSelected(data: { id, notifySubscribers, lastClientId, lastProductId }) {
    // Communicate to the other subscribers
    if (data) {
      this.clientSub.next(data);
      this.clientId = data.id;
      // Save in localstorage
      localStorage.setItem('globalClientIdSelected', data.id);
    } else {
      this.clientSub.next(null);
    }
  }

  getClientSelected() {
    const data = JSON.parse(localStorage.getItem('globalClientIdSelected'));
    if (data) {
      return data;
    }
    return -1;
  }
}
