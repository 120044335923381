import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


export const APP_ROUTES: Routes = [
  {path: 'home', loadChildren: './core/home/home.module#HomeModule'},
  {path: 'login', loadChildren: './core/login/login.module#LoginModule'},
  {path: 'reset-password', loadChildren: './core/reset-password/reset-password.module#ResetPasswordModule'},
  {path: 'restore-password', loadChildren: './core/restore-password/restore-password.module#RestorePasswordModule'},
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutesModule {
}
