import {NgModule} from '@angular/core';
import {LoaderService} from './loader.service';
import {LoginService} from './login.service';
import {ClientService} from './client.service';
import {ModalService} from './modal.service';
import {UserService} from './user.service';
import {CalendarService} from './calendar.service';
import {VehicleService} from './vehicle.service';

@NgModule({
  providers: [
    LoaderService,
    LoginService,
    ClientService,
    ModalService,
    UserService,
    CalendarService,
    VehicleService
  ]
})
export class SharedServicesModule {
}
