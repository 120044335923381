import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoaderService {
  visible = new BehaviorSubject<boolean>(false);

  showLoader(visible: boolean) {
    setTimeout(() => {
      this.visible.next(visible);
    });
  }
}
