// MODULES
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RequestInterceptorModule} from './shared/interceptor/request-interceptor.module';
import {TranslationModule} from './shared/translation/translation.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AgmCoreModule} from '@agm/core';
// ROUTES
import {AppRoutesModule} from './app-routes.module';
// COMPONENTS
import {AppComponent} from './app.component';
import {ModalComponent} from './shared/modal/modal.component';
import {SharedServicesModule} from './services/shared-services.module';


@NgModule({
  declarations: [
    AppComponent,
    ModalComponent
  ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AppRoutesModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslationModule,
    NgxPermissionsModule.forRoot(),
    RequestInterceptorModule.forRoot(),
    AgmCoreModule.forRoot({
      libraries: ['places'],
      apiKey: 'AIzaSyAvymTGJaN0dsr8TNpLh5oc_w_jPZfNN0E'
    }),
    SharedServicesModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
