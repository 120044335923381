import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from './services/loader.service';
import {takeWhile} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  isSubscribed = true;
  showLoader = false;

  constructor(private loader: LoaderService,
              private translate: TranslateService,
              private permissionsService: NgxPermissionsService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    translate.use('en');
    // TODO: At the moment, use 'en' by default
    // Used to set the lang based on the browser lang
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
  }

  ngOnInit() {
    // Load roles with permissions
    this.setRolesAndPermissions();
    // Show initial loader
    this.loader.visible.pipe(takeWhile(() => this.isSubscribed))
      .subscribe((val: boolean) => {
        this.showLoader = val;
      });
  }

  ngOnDestroy(): void {
    // Finish all subscriptions on destroy
    this.isSubscribed = false;
  }

  setRolesAndPermissions() {
    // Load roles with permissions
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile && profile.roles) {
      this.permissionsService.loadPermissions(profile.roles);
    }
  }
}
