import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/loader.service';
import {ModalService} from '../../services/modal.service';


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  callCounter = 0; // Used to control that the loader doesn't hide until all the requests are finished

  constructor(private injector: Injector,
              private router: Router,
              private loader: LoaderService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add headers to every request
    const dupReq = request.clone({headers: this.getHeaders()});
    // Show global loader
    this.loader.showLoader(true);
    this.callCounter++;
    return next.handle(dupReq).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Do stuff with response if you want
        }
      }, (error: any) => {
        const modalService = this.injector.get(ModalService);
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (error instanceof HttpErrorResponse) {
          // Server or connection error happened
          if (!navigator.onLine) {
            // Handle offline error
            modalService.setModal({title: 'No hay conexión a internet', text: '', visible: true, btnName: 'Cerrar'});
          } else if (error.status !== 0) {
            // Handle Http Error (error.status === 403, 404...)
            let text = '';
            switch (error.status) {
              case 401:
                text = error.error.map(err => err.message).join(', ').toString();
                modalService.setModal({title: 'Error', text: text, visible: true, btnName: 'Ir al login', url: '/login'});
                break;
              default:
                if (profile && profile.token) {
                  if (error.error) {
                    text = error.error.map(err => err.message).join(', ').toString();
                    modalService.setModal({title: 'Error', text: text, visible: true, btnName: 'Cerrar'});
                  } else {
                    modalService.setModal({title: error.status.toString(), text: error.message, visible: true, btnName: 'Ce'});
                  }
                } else { // No token
                  modalService.setModal({title: 'Error', text: 'Sesión expirada', visible: true, btnName: 'Ir al login', url: '/login'});
                }
                break;
            }
          }
        }
      })
    ).pipe(finalize(() => {
      this.callCounter--;
      if (this.callCounter === 0) {
        this.loader.showLoader(false);
      }
    })); // Hide global loader
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('profile') ? 'Bearer ' + JSON.parse(localStorage.getItem('profile')).token : '');
  }
}
