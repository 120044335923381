import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RequestInterceptorService} from './request-interceptor.service';

@NgModule({
  declarations: [],
  providers: [RequestInterceptorService]
})
export class RequestInterceptorModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RequestInterceptorModule,
      providers: [
        {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true}
      ]
    };
  }
}
