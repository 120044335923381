import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoginService {

  BASE_URL: string;
  profileImgSub = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.envName;
  }

  doLogin(data: {email, password}) {
    const endpoint = 'login';
    return this.http.post(this.BASE_URL + endpoint, data);
  }

  resetPass(data: {email}) {
    const endpoint = 'users/password/reset';
    return this.http.post(this.BASE_URL + endpoint, data);
  }

  restorePass(data: {token, password}) {
    const endpoint = 'users/password';
    return this.http.put(this.BASE_URL + endpoint, data);
  }
}
