import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ModalService {
  public modalData: BehaviorSubject<{}> = new BehaviorSubject<{}>(null);

  setModal(modalData: { title: string, text: string, visible: boolean, btnName: string, url?: string, isHtmlTxt?: boolean }) {
    this.modalData.next(modalData);
  }
}
