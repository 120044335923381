import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class VehicleService {
  BASE_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.envName;
  }

  listVehicles() {
    const endpoint = 'vehicles';
    return this.http.get(this.BASE_URL + endpoint);
  }

  listVehicleLastPositions() {
    const endpoint = 'vehicleLastPositions';
    return this.http.get(this.BASE_URL + endpoint);
  }

  listVehicleRoute(registration, startDate, endDate) {
    const endpoint = 'vehicleRoute/' + registration + '/' + startDate + '/' + endDate;
    return this.http.get(this.BASE_URL + endpoint);
  }
}
